import Arrow from 'assets/svg/arrow/arrow-forward-bold.svg'
import Button from 'common/components/Button'
import Image from 'common/components/Image'
import theme from 'common/theme'
import React, { CSSProperties, useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'

const control = `
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  margin-top: -22px;
  background-color: ${theme.colors.white};
  border-radius: 50%;
  box-shadow: 0 6px 16px 0 rgba(134, 134, 134, 0.14);
  font-size: 18px;
  transition: 0.6s ease;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
  svg {
    path {
      fill: ${theme.colors.orange};
    }
  }
`

const fadeAnimation = keyframes`
  from {opacity: .4}
  to {opacity: 1}
`

const Slides = styled.div`
  display: ${(props) =>
    props.slideIndex === props.index ? 'block' : 'none'};
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1s;
  animation-name: ${fadeAnimation};
  animation-duration: 1s;
  margin: 0 -10px;

  img {
    width: calc(${(props) => 100 / props.imagePerSlide}% - 20px);
    margin: 0 10px;
  }
`

const Prev = styled.a`
  ${control};
  left: 16px;
`

const Next = styled.a`
  ${control};
  right: 16px;
`

const LeftArrow = styled(Arrow)`
  transform: scaleX(-1);
`

const WrapperDot = styled.div`
  position: absolute;
  bottom: 20px;

  ${(props) => {
    if (props.dotPosition === 'center') {
      return `
      display: flex; 
      width: 100%; 
      justify-content: center;
      `
    }

    return 'left: 20px;'
  }}
  ${({ style }) => style}
`
const Dot = styled.div`
  cursor: pointer;
  height: 8px;
  width: 8px;
  margin: 0 2px;
  background-color: ${theme.colors.white};
  border-radius: 50%;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.13);
  display: inline-block;
  transition: background-color 0.6s ease;
  &.active {
    width: 20px;
    border-radius: 4px;
    transition: width 0.5s;
  }
  ${({ style }) => style}
`
const ButtonDetail = styled(Button)`
  position: absolute;
  right: 20px;
  border-radius: 8px;
  bottom: 20px;
  height: 36px;
`

/**
 * @param {String} width
 * @return {String}
 */
const ContainerSelectionWidth = (width: string): string => {
  return width ? `width: ${width};` : '1140px'
}

const Container = styled.div`
  ${(props) => ContainerSelectionWidth(props.width)}
  height: ${(props) => props.height};
  position: relative;
  margin: ${(props) => props.margin};
  &:hover {
    .carousel-control {
      opacity: 1;
    }
  }
`
const ImageContent = styled(Image)`
  cursor: ${(props) =>
    props.type === 'gallery' ? 'default' : 'pointer'};
`
interface ICarousel {
  slideIndex?: number
  images: Array<string> | Array<object>
  imageRadius?: string
  interval?: number
  width?: string
  height?: string
  buttonText?: string
  imagePerSlide?: number
  onImageClick?: Function
  onButtonClick?: Function
  margin?: string
  type?: string
  dotPosition?: string
  showNavigate?: boolean
  styleDot?: () => CSSProperties
  styleContainerDot?: () => CSSProperties
  customSlide?: (data: object) => JSX.Element
}

function Carousel({
  slideIndex: defaultSlideIndex = 1,
  images,
  imageRadius = '0',
  interval = 7000,
  width,
  height = 'auto',
  imagePerSlide = 1,
  buttonText,
  onImageClick = () => null,
  onButtonClick = () => null,
  margin = 'auto',
  type = '',
  dotPosition = 'default',
  showNavigate = true,
  styleDot,
  styleContainerDot,
  customSlide,
  ...otherProps
}: ICarousel): JSX.Element {
  const [isMouseEnter, setIsMouseEnter] = useState<boolean>(false)
  const [slideIndex, setSlideIndex] = useState<number>(
    defaultSlideIndex,
  )
  const slides: Array<Array<{
    id: number
    image?: string
  }>> = []

  images.forEach((image, index) => {
    const imagesSlideIndex = Math.floor(index / Number(imagePerSlide))
    if (!slides[imagesSlideIndex]) slides[imagesSlideIndex] = []

    if (typeof image === 'string') {
      return slides[imagesSlideIndex].push({
        id: index,
        image,
      })
    }

    if (typeof image === 'object')
      return slides[imagesSlideIndex].push({
        id: index,
        ...image,
      })

    return null
  })

  const showSlides = (index: number): void => {
    if (index > slides.length) return setSlideIndex(1)
    if (index < 1) return setSlideIndex(slides.length)
    return setSlideIndex(index)
  }

  const goToSlide = (index: number): void => {
    setSlideIndex(index)
  }

  useEffect(() => {
    let intervalSlide = null
    if (isMouseEnter) {
      clearInterval(intervalSlide)
    } else {
      intervalSlide = setInterval(() => {
        showSlides(slideIndex + 1)
      }, interval)
    }
    return () => clearInterval(intervalSlide)
  }, [interval, isMouseEnter, showSlides, slideIndex])

  return (
    <Container
      data-testid="carousel-test"
      width={width}
      height={height}
      margin={margin}
      onMouseEnter={() => setIsMouseEnter(true)}
      onMouseLeave={() => setIsMouseEnter(false)}
      {...otherProps}
    >
      {slides.map((slide, index) => {
        return (
          <Slides
            slideIndex={slideIndex}
            index={index + 1}
            key={String(`slide-${index}`)}
            imagePerSlide={imagePerSlide}
          >
            {slide.map((image) => {
              if (customSlide) {
                return customSlide(image)
              }
              return (
                <ImageContent
                  lazy
                  src={image.image}
                  height={height}
                  objectFit="cover"
                  key={`image-${image.id}`}
                  type={type}
                  onClick={(e) =>
                    type === 'gallery'
                      ? e.preventDefault()
                      : onImageClick(image)
                  }
                  radius={imageRadius}
                />
              )
            })}
          </Slides>
        )
      })}
      {showNavigate && slides.length > 1 && (
        <>
          <Prev
            data-testid="carousel-control-prev-test"
            className="carousel-control"
            onClick={() => showSlides(slideIndex - 1)}
          >
            <LeftArrow />
          </Prev>
          <Next
            data-testid="carousel-control-next-test"
            className="carousel-control"
            onClick={() => showSlides(slideIndex + 1)}
          >
            <Arrow transform-origin="50% 50%" />
          </Next>
        </>
      )}

      <WrapperDot dotPosition={dotPosition} style={styleContainerDot}>
        {slides.map((slide, index) => {
          return (
            <Dot
              style={styleDot}
              data-testid={`carousel-dot-test-${index}`}
              key={String(`dot-${index}`)}
              className={index === slideIndex - 1 ? 'active' : ''}
              onClick={() => goToSlide(index + 1)}
            />
          )
        })}
      </WrapperDot>
      {buttonText && (
        <ButtonDetail
          letterCase="capitalize"
          color="black"
          width="110px"
          padding="8px"
          fontWeight="bold"
          onClick={onButtonClick}
        >
          {buttonText}
        </ButtonDetail>
      )}
    </Container>
  )
}

export default Carousel
