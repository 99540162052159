import CancelIcon from 'assets/svg/cancel-login/cancel.svg'
import Button from 'common/components/Button'
import Typography from 'common/components/Typography'
import { useTranslation } from 'react-i18next'
import MailIllustration from 'services/Auth/assets/svg/otp-illustration/mail-illustration.svg'
import styled from 'styled-components'

const Wrapper = styled.div`
  text-align: center;
  padding: 50px;
  ${(props) =>
    props.responsive &&
    `
    @media (max-width: 1199.98px) {
      padding: 50px 24px 24px;
    }
  `}
`
const ButtonLogin = styled(Button)`
  font-size: 16px;
`
const Icon = styled.span`
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
`

function PopupMessage({
  onClose,
  onButtonClick,
  responsive = false,
}: {
  onClose: Function
  onButtonClick: Function
  responsive?: boolean
}): JSX.Element {
  const { t } = useTranslation(['auth'])
  return (
    <Wrapper
      responsive={responsive}
      data-testid="popup-message-user-exist"
    >
      <Icon onClick={onClose} data-testid="close-icon-test">
        <CancelIcon />
      </Icon>
      <MailIllustration width="140px" />
      <Typography type="bh4" textAlign="center" marginTop="14px">
        {t('ACCOUNT_HAS_BEEN_REGISTER')}
      </Typography>
      <Typography type="rh6" textAlign="center" marginTop="15px">
        {t('DESC_PLEASE_LOGIN')}
      </Typography>
      <ButtonLogin
        size="large"
        block
        fontWeight="bold"
        margin="50px 0 0"
        onClick={onButtonClick}
        data-testid="button-login-test"
      >
        {t('SIGNIN')}
      </ButtonLogin>
    </Wrapper>
  )
}

export default PopupMessage
