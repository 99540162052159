import Carousel from 'common/components/Carousel'
import BusinessSolutionHeader from 'common/components/Header/BusinessSolutionHeader'
import Image from 'common/components/Image'
import LanguageDropdown from 'common/components/LanguageDropdown'
import Typography from 'common/components/Typography'
import { WEB_DESKTOP_RALALI_URL } from 'common/constants'
import theme from 'common/theme'
import MediaHelper from 'lib/media-helper'
import transformData from 'lib/transform/array-to-camel-case'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from 'stores/hooks'
import styled, { css } from 'styled-components'

const INTERVAL_SLIDE = 5000

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 100% !important;
`

const ContainerShowCase = styled.div`
  display: fixed;
  position: relative;
  top: 0;
  left: 0;
  width: 50%;
  height: auto;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  background-attachment: fixed;
  box-shadow: inset 0 0 0 2000px ${theme.colors.black + 60};
  ${({ background }) => `background-image: url(${background});`};
  ${MediaHelper('laptop')`display: none;`}
`

const WrapperChildren = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
  overflow-y: scroll;
  flex-direction: column;

  ${MediaHelper('laptop')`margin-top: 90px;`}
`

const WrapperSlide = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  height: fit-content;
  gap: 12px;
  padding: 10px;
  align-items: center;
`

const StyledButton = styled.a`
  ${theme.typography.bh6}
  text-decoration: none;
  color: ${theme.colors.white};
  display: flex;
  background-color: transparent;
  border: 1.5px solid ${theme.colors.white};
  width: fit-content;
  height: 48px;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding-inline: 12px;
  border-radius: ${theme.radius.lg};
  cursor: pointer;
`

const ContainerHeaderStyle = css`
  width: 50%;
  display: flex;
  justify-content: center;
  margin-right: auto;

  ul > div:nth-child(3) {
    left: 80px;
    transform: translate(0, 0);
  }

  > a {
    display: none;
  }

  ${MediaHelper(
    'laptop',
  )`display: flex; justify-content: space-between; > a {
    display: block;
  }`}
`

const StyleDot = () => css`
  &.active {
    width: 8px;
    background-color: ${theme.colors.redOrange};
  }
`

const StyleContainerDot = () => css`
  bottom: -20px;
`

interface ISlide {
  id?: string
  image: string
  title: string
  description: string
  buttonUrl: string
  buttonText: string
}

interface ILayoutAuth {
  children: JSX.Element | JSX.Element[]
  seo?: object
  metaData?: JSX.Element
  lang?: string
}

function CustomSlide(data: ISlide): JSX.Element {
  return (
    <WrapperSlide key={`slide-${data.id}`}>
      <Image
        lazy
        src={data.image}
        width="350px"
        height="300px"
        objectFit="contain"
        key={`image-${data.id}`}
      />
      <Typography textAlign="center" type="bh2" color="white">
        {data.title}
      </Typography>
      <Typography textAlign="center" type="rh4" color="white">
        {data.description}
      </Typography>
      <StyledButton href={data.buttonUrl}>
        {data.buttonText}
      </StyledButton>
    </WrapperSlide>
  )
}

function ShowCase({
  slides,
  background,
}: {
  slides: ISlide[]
  background: string
}): JSX.Element {
  return (
    <ContainerShowCase background={background}>
      <Carousel
        images={slides}
        showNavigate={false}
        dotPosition="center"
        interval={INTERVAL_SLIDE}
        styleDot={StyleDot}
        styleContainerDot={StyleContainerDot}
        customSlide={CustomSlide}
      />
    </ContainerShowCase>
  )
}

function LayoutAuth({
  children,
  seo,
  metaData,
  lang,
}: ILayoutAuth): JSX.Element {
  const router = useRouter()

  const [slides, setSlides] = useState<any[]>([])
  const [menus, setMenus] = useState<any[]>([])
  const [background, setBackground] = useState<string>('')

  const { businessSolution } = useStoreActions((actions) => actions)
  const { businessSolution: businessSolutionState } = useStoreState(
    (state) => state,
  )

  const getSection = useCallback(
    (group: string): any => {
      const result = businessSolutionState.data?.sections.find(
        (section) => section.group === group,
      )
      if (!result) return null
      return result.data
    },
    [businessSolutionState],
  )

  useEffect(() => {
    const params = { page_code: 'login' }
    businessSolution.getData(params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (businessSolutionState) {
      const sectionCarousel = getSection('login_register_carousel')
        ?.items
      const sectionHeader = getSection('login_register_header')?.items
      const sectionBackground = getSection(
        'login_register_background',
      )?.image_url

      setSlides(transformData(sectionCarousel))
      setMenus(transformData(sectionHeader))
      setBackground(sectionBackground)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessSolutionState])

  const extraMenu = (
    <LanguageDropdown
      initialLang={lang}
      onChange={router.reload}
      color="white"
    />
  )

  return (
    <Container>
      <NextSeo
        canonical={`${WEB_DESKTOP_RALALI_URL}${router.pathname}`}
        {...seo}
      />
      {metaData}
      <BusinessSolutionHeader
        lang={lang}
        position="absolute"
        menus={menus}
        styleContainer={ContainerHeaderStyle}
        extra={extraMenu}
      />
      <ShowCase slides={slides} background={background} />
      <WrapperChildren>{children}</WrapperChildren>
    </Container>
  )
}

export default LayoutAuth
