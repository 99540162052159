import ArrowDown from 'assets/svg/arrow/arrow-down.svg'
import ArrowUp from 'assets/svg/arrow/arrow-up.svg'
import Loader from 'common/components/Loader'
import Typography from 'common/components/Typography'
import theme from 'common/theme'
import MediaHelper from 'lib/media-helper'
import {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'

interface IIcon {
  open: ReactNode
  close: ReactNode
  color: string
}

interface DropdownType {
  children?: any
  id?: string
  disabled?: boolean
  placeholder?: string
  required?: boolean
  bordered?: boolean
  fontSize?: string
  fontWeight?: string
  block?: boolean
  width?: string
  height?: string
  margin?: string
  padding?: string
  backgroundColor?: string
  color?: string
  helperText?: string
  error?: boolean
  value?: string | number
  onChange?: Function
  onClick?: Function
  isLoading?: boolean
  heightList?: string
  icon?: Partial<IIcon>
  styleContainerList?: () => CSSProperties
  borderColor?: string
  iconPrefix?: ReactNode
}

interface IDropdownItem {
  children?: any
  disabled?: boolean
  height?: string
  padding?: string
  value?: string
  highlighted?: boolean
  onMouseDown: Function
}

const setBorder = (bordered, showItems, items, borderColor) => {
  let css = `
    border: 1px solid ${borderColor};
    :focus {
      outline-width: 0;
      outline-color: transparent;
      outline-style: none;
      border: 1px solid ${theme.colors.orange};
    }
  `
  if (!bordered) css = `${css}; border: none;`
  if (showItems && items?.length) {
    css = `
      ${css};
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: none;
    `
  }
  return css
}

const Container = styled.div`
  position: relative;
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};
  ${(props) => (props.block ? 'width: 100%;' : '')};
  * {
    cursor: pointer;
  }

  ${MediaHelper('mobile')`width: 100%;`}
`

const StyledHelperTextWrapper = styled.div`
  min-height: 24px;
`

const Select = styled.div.attrs((props) => ({
  id: props.id,
  disabled: props.disabled,
  placeholder: props.placeholder,
  required: props.required,
}))`
  width: 100%;
  height: ${(props) => props.height};
  padding: ${(props) =>
    props.iconPrefix ? '11px 45px 12px' : props.padding};
  background-color: ${(props) => props.backgroundColor};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  color: ${(props) => props.color};
  border-radius: ${theme.radius.lg};
  box-sizing: border-box;
  ${(props) =>
    setBorder(
      props.bordered,
      props.showItems,
      props.items,
      props.borderColor,
    )}
  ${(props) =>
    props.disabled
      ? `background-color: ${theme.colors.grayLighter}; cursor: not-allowed;`
      : ''};
  display: flex;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
`

const IconPrefix = styled.label`
  position: absolute;
  left: 15px;
  display: flex;
  align-items: center;
  height: 100%;
`

const Icon = styled.label`
  position: absolute;
  right: 15px;
  display: flex;
  align-items: center;
  height: ${(props) => props.height};
  svg {
    path {
      fill: ${(props) =>
        props.disabled ? theme.colors.black50 : props.color};
    }
  }
`

const SelectedValueWrapper = styled.div`
  width: 92%;
`

const StyledList = styled.ul`
  position: absolute;
  width: 100%;
  border-bottom-left-radius: ${theme.radius.base};
  border-bottom-right-radius: ${theme.radius.base};
  border: 1px solid ${(props) => props.borderColor};
  background-color: ${theme.colors.white};
  box-sizing: border-box;
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: auto;
  max-height: ${(props) => props.heightList || 'unset'};
  z-index: ${theme.zIndex.dropdown};
  ${({ style }) => style}
`

const StyledListItem = styled.li`
  color: ${theme.colors.black};
  font-size: 13px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: ${(props) => props.height};
  padding: ${(props) => props.padding};
  ${(props) =>
    props.highlighted
      ? `background-color: ${theme.colors.grayLighter}; color: ${theme.colors.black};`
      : ''};
  :hover {
    background-color: ${theme.colors.grayLighter};
    color: ${theme.colors.black};
  }
  :last-child {
    :hover {
      border-bottom-left-radius: ${theme.radius.base};
      border-bottom-right-radius: ${theme.radius.base};
    }
  }
  ${(props) =>
    props.disabled &&
    `
      background-color: ${theme.colors.grayLight}; 
      cursor: not-allowed;
      :hover {
        background-color: ${theme.colors.grayLight};
      }
      * {
        cursor: not-allowed;
      }
    `};
`

function arrayChildren(children): any[] {
  const tempChildren = children
  if (Array.isArray(tempChildren)) return [...tempChildren]
  if (typeof tempChildren === 'object') return [tempChildren]
  return [] // set empty array if children not object or array
}

function DropdownItem({
  children,
  disabled,
  height,
  onMouseDown,
  highlighted,
  padding,
  value,
}: IDropdownItem) {
  const handleMouseDown = useCallback(() => {
    if (disabled) return
    onMouseDown(value)
  }, [disabled, onMouseDown, value])

  return (
    <StyledListItem
      data-testid={`item-test-${value}`}
      padding={padding}
      key={JSON.stringify(value)}
      value={value}
      disabled={disabled}
      height={height}
      highlighted={highlighted}
      onMouseDown={handleMouseDown}
    >
      {children}
    </StyledListItem>
  )
}

export default function Dropdown({
  children,
  id = '',
  disabled,
  placeholder,
  required,
  bordered = true,
  fontSize = theme.dropdown.fontSize,
  fontWeight = 'normal',
  block = false,
  width = theme.dropdown.width,
  height = theme.dropdown.height,
  heightList,
  margin = theme.dropdown.margin,
  padding = theme.dropdown.padding,
  backgroundColor = theme.colors.white,
  color = theme.colors.black50,
  borderColor = theme.colors.grayLight,
  helperText = '',
  error = false,
  value = '',
  onChange = () => {
    return null
  },
  onClick = () => {
    return null
  },
  isLoading = false,
  icon = {
    color: theme.colors.orange,
  },
  iconPrefix,
  styleContainerList,
}: DropdownType): JSX.Element {
  const [currentValue, setCurrentValue] = useState(value)
  const [showItems, setShowItems] = useState(false)
  const selectRef = useRef(null)
  const selectList = useRef(null)
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target) &&
        selectList.current &&
        !selectList.current.contains(event.target)
      ) {
        setShowItems(false)
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [selectRef, selectList])

  const handleClick = () => {
    if (disabled) {
      return
    }
    onClick()
    setShowItems(!showItems)
  }
  const onItemClick = useCallback(
    (val) => {
      setCurrentValue(val)
      setShowItems(false)
      onChange(val)
    },
    [onChange],
  )
  const onBlur = () => {
    setShowItems(false)
  }

  const fetchSelectedValue = (val) => {
    const indexVal = arrayChildren(children).findIndex(
      (opt) => opt?.props?.value === val,
    )
    if (indexVal >= 0) {
      if (arrayChildren(children)?.[indexVal]?.props?.label) {
        return (
          <SelectedValueWrapper>
            {arrayChildren(children)?.[indexVal]?.props?.label}
          </SelectedValueWrapper>
        )
      }

      return (
        <SelectedValueWrapper>
          {arrayChildren(children)?.[indexVal]?.props?.children}
        </SelectedValueWrapper>
      )
    }
    return (
      <Typography
        fontSize={fontSize}
        fontWeight={fontWeight}
        color={currentValue ? `black` : 'black50'}
      >
        {currentValue || placeholder}
      </Typography>
    )
  }

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  return (
    <Container
      width={width}
      margin={margin}
      block={block}
      ref={selectRef}
    >
      {iconPrefix && <IconPrefix>{iconPrefix}</IconPrefix>}
      <Icon
        htmlFor={id}
        disabled={disabled}
        height={height}
        onClick={handleClick}
        color={icon.color}
      >
        {isLoading && <Loader radius="50px" />}
        {!isLoading &&
          (showItems
            ? icon.close ?? <ArrowUp />
            : icon.open ?? <ArrowDown />)}
      </Icon>
      <Select
        data-testid="select-test"
        id={id}
        disabled={disabled}
        required={required}
        bordered={bordered}
        fontSize={fontSize}
        fontWeight={fontWeight}
        width={width}
        height={height}
        padding={padding}
        iconPrefix={iconPrefix}
        backgroundColor={backgroundColor}
        color={color}
        borderColor={borderColor}
        value={currentValue}
        onChange={() => {
          return null
        }}
        onClick={handleClick}
        onBlur={onBlur}
        error={error}
        helperText={helperText}
        showItems={showItems}
        items={arrayChildren(children)}
      >
        {fetchSelectedValue(currentValue)}
      </Select>
      {showItems ? (
        <StyledList
          style={styleContainerList}
          heightList={heightList}
          borderColor={theme.colors.grayLight}
          ref={selectList}
        >
          {arrayChildren(children).map((item) => {
            return (
              <DropdownItem
                padding={padding}
                key={item?.props?.value}
                value={item?.props?.value}
                disabled={item?.props?.disabled}
                height={height}
                highlighted={currentValue === item?.props?.value}
                onMouseDown={onItemClick}
              >
                {item?.props?.children}
              </DropdownItem>
            )
          })}
        </StyledList>
      ) : null}
      {helperText && (
        <StyledHelperTextWrapper>
          <Typography
            color={error ? 'red' : 'black'}
            fontSize="12px"
            lineHeight="24px"
          >
            {helperText}
          </Typography>
        </StyledHelperTextWrapper>
      )}
    </Container>
  )
}
