import Modal from 'common/components/Modal'
import { useCallback } from 'react'
import OtpForm from 'services/Auth/components/OtpForm'
import PopupMessageUserExist from 'services/Auth/components/PopupMessageUserExist'
import OtpChannel from 'services/Auth/components/VerificationChannel'

interface IOtpFlowModal {
  isOpen: boolean
  handleClose: () => void
  step: string
  isLoading?: boolean
  handlePostSendOtp: Function
  handlePostResendOtp?: Function
  handlePostVerifyOtp: Function
  handleSetStep: Function
  handleRedirectLogin?: Function
  handleTimeoutOtp?: Function
  otpType?: 'OTP' | 'OTA'
  otpTimer?: string
  responsive?: boolean
}

function OtpFlowModal({
  isOpen,
  handleClose,
  step,
  isLoading,
  handlePostSendOtp,
  handlePostResendOtp,
  handlePostVerifyOtp,
  handleSetStep,
  handleRedirectLogin,
  handleTimeoutOtp,
  otpType = 'OTP',
  otpTimer,
  responsive,
}: IOtpFlowModal): JSX.Element {
  const handleBackOtpForm = useCallback(() => {
    handleSetStep('otp-channel')
  }, [handleSetStep])

  return (
    <Modal
      isOpen={isOpen}
      onOverlayClick={handleClose}
      width="560px"
      height="auto"
      padding="0"
      borderRadius="10px"
      responsive={responsive}
    >
      <>
        {step === 'otp-channel' && (
          <OtpChannel
            handleClose={handleClose}
            postSendOtp={handlePostSendOtp}
            isLoadingChannel={isLoading}
            type={otpType}
            otpTimer={otpTimer}
            responsive={responsive}
            handleTimeOut={handleTimeoutOtp}
          />
        )}
        {step === 'otp-form' && (
          <OtpForm
            back={handleBackOtpForm}
            resendOtp={handlePostResendOtp}
            onSubmit={handlePostVerifyOtp}
            type={otpType}
            handleTimeOut={handleTimeoutOtp}
            responsive={responsive}
          />
        )}
        {step === 'existing-user' && (
          <PopupMessageUserExist
            onClose={handleClose}
            onButtonClick={handleRedirectLogin}
            responsive={responsive}
          />
        )}
      </>
    </Modal>
  )
}

export default OtpFlowModal
