import { css } from 'styled-components'

type IDeviceProp = {
  value: number
}
type IBreakpointDevice = {
  mobile: IDeviceProp
  tablet: IDeviceProp
  laptop: IDeviceProp
  desktop: IDeviceProp
  extra: IDeviceProp
}

type ILayout = {
  breakpoints: IBreakpointDevice
  media?: object
}

const MediaHelper = (breakpoint: string) => {
  const layout: ILayout = {
    breakpoints: {
      mobile: { value: 576 },
      tablet: { value: 768 },
      laptop: { value: 992 },
      desktop: { value: 1200 },
      extra: { value: 1400 },
    },
  }

  layout.media = {}

  Object.keys(layout.breakpoints).forEach((breakpointItem) => {
    layout.media[breakpointItem] = (...args: any) => {
      const breakpointValue =
        layout.breakpoints[breakpointItem].value / 16
      return css`
        @media only screen and (max-width: ${breakpointValue}em) {
          ${css(...args)}
        }
      `
    }
  })

  return layout.media[`${breakpoint}`]
}

export default MediaHelper
