import { getCookie } from 'lib/cookie'
import { NextPageContext } from 'next'

/**
 * Returns the access token value
 *
 * @param context
 * @param cookieName
 */
const checkToken = (
  cookieName: string,
  context?: NextPageContext,
): string | undefined => {
  if (context?.req && context?.req.headers) {
    const accessToken = getCookie(cookieName, context)

    return context.res && accessToken
  }
  const accessToken = getCookie(cookieName)

  return accessToken
}

export default checkToken
