import authConfig from 'config/auth'
import isMobile from 'is-mobile'
import checkToken from 'lib/auth/check-token'
import redirect from 'lib/common/redirect'
import { NextPage, NextPageContext } from 'next'
import { parseCookies } from 'nookies'

/**
 * Without Auth wrapper for component that automatically redirect to specific page if the authenticated user tries to access it
 * @param WrappedComponent
 * @param redirectPath
 */
const WithoutAuth = (
  WrappedComponent: NextPage<any>,
  redirectPath: string,
  mobileRedirectUrl?: string,
  pageName?: string,
): any => {
  const WithoutAuthComponent = (props: object) => {
    return <WrappedComponent {...props} />
  }

  WithoutAuthComponent.getInitialProps = async (
    ctx: NextPageContext,
  ) => {
    if (ctx && mobileRedirectUrl) {
      const userAgent = ctx?.req?.headers['user-agent']
      if (isMobile({ ua: userAgent })) {
        redirect(mobileRedirectUrl, '', ctx)
        return {}
      }
    }
    const cookies = parseCookies(ctx)
    const lang = cookies.lang || 'id'
    const accessToken = checkToken(authConfig.accessTokenName, ctx)
    const refreshToken = checkToken(authConfig.refreshTokenName, ctx)
    if (accessToken || refreshToken) redirect(redirectPath, '', ctx)
    return {
      accessToken,
      pageName,
      lang,
    }
  }

  return WithoutAuthComponent
}

export default WithoutAuth
